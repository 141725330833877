<template>
    <div style="padding: 0 20px">
        <el-form :model="addForm" ref="addForm" class="demo-ruleForm" :hide-required-asterisk="true">
            <div class="big-title">省份分布</div>
            <div class="form-item-1 flex">
                <div class="item-left-first" style="margin-bottom: 0"></div>
                <div class="item-right">
                    <div class="item-text">成交客户占比</div>
                    <div class="item-text">成交客单价</div>
                </div>
            </div>
            <div class="form-item-1 flex" v-for="(regionItem, regionIndex) in addForm.provinces_distribution_data" :key="`provinces_${regionIndex}`">
                <div class="item-left-box">
                    <div class="item-left-first">{{regionItem.region_code | filterProvinces}}</div>
                    <div class="item-left-first">波动范围</div>
                </div>
                <div class="item-right">
                    <div class="item-text">
                        <el-form-item :prop="`provinces_distribution_data.${regionIndex}.finish_customer_number`"
                                      :rules="{required: true, message: '成交客户占比不能为空', trigger: 'blur'}">
                            <el-input-number class="number-input1" v-model="regionItem.finish_customer_number" :min="1" :max="999999999" :precision="0" :controls="false"></el-input-number>
                        </el-form-item>
                        <div class="flex">
                            <el-form-item :prop="`provinces_distribution_data.${regionIndex}.finish_customer_number_range_min`"
                                          :rules="{required: true, message: '低值不能为空', trigger: 'blur'}">
                                <el-input-number class="number-input1" v-model="regionItem.finish_customer_number_range_min"
                                                 :min="-99" :max="regionItem.finish_customer_number_range_max ? regionItem.finish_customer_number_range_max - 1 : 99" :precision="0" :controls="false"></el-input-number>
                            </el-form-item>
                            <span class="prompt-text">%</span>
                            <span class="prompt-text" style="margin-right: 4px">-</span>
                            <el-form-item :prop="`provinces_distribution_data.${regionIndex}.finish_customer_number_range_max`"
                                          :rules="{required: true, message: '高值不能为空', trigger: 'blur'}">
                                <el-input-number class="number-input1" v-model="regionItem.finish_customer_number_range_max"
                                                 :min="regionItem.finish_customer_number_range_min ? regionItem.finish_customer_number_range_min + 1 : -98" :max="100" :precision="0" :controls="false"></el-input-number>
                            </el-form-item>
                            <span class="prompt-text">%</span>
                        </div>
                    </div>
                    <div class="item-text">
                        <el-form-item :prop="`provinces_distribution_data.${regionIndex}.finish_price`"
                                      :rules="{required: true, message: '成交客单价不能为空', trigger: 'blur'}">
                            <el-input-number class="number-input1" v-model="regionItem.finish_price" :min="0.01" :max="999999999.99" :precision="2" :controls="false"></el-input-number>
                        </el-form-item>
                        <div class="flex">
                            <el-form-item :prop="`provinces_distribution_data.${regionIndex}.finish_price_range_min`"
                                          :rules="{required: true, message: '低值不能为空', trigger: 'blur'}">
                                <el-input-number class="number-input1" v-model="regionItem.finish_price_range_min"
                                                 :min="-99" :max="regionItem.finish_price_range_max ? regionItem.finish_price_range_max - 1 : 99" :precision="0" :controls="false"></el-input-number>
                            </el-form-item>
                            <span class="prompt-text">%</span>
                            <span class="prompt-text" style="margin-right: 4px">-</span>
                            <el-form-item :prop="`provinces_distribution_data.${regionIndex}.finish_price_range_max`"
                                          :rules="{required: true, message: '高值不能为空', trigger: 'blur'}">
                                <el-input-number class="number-input1" v-model="regionItem.finish_price_range_max"
                                                 :min="regionItem.finish_price_range_min ? regionItem.finish_price_range_min + 1 : -98" :max="100" :precision="0" :controls="false"></el-input-number>
                            </el-form-item>
                            <span class="prompt-text">%</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="big-title">
                <div>
                    职业分布比例
                    <span class="big-title-prompt">(总和是100%)</span>
                    <el-input size="medium" placeholder="总和" style="width: 100px;" disabled :value="totalScore['jobs_distribution_data']"></el-input>
                </div>
            </div>
            <div class="form-item-2">
                <div class="item-box" v-for="(jobItem, jobKey, jobIndex) in addForm.jobs_distribution_data" :key="`job_${jobIndex}`">
                    <div class="item-text">{{jobKey | filterJob}}</div>
                    <div class="flex">
                        <el-form-item class="item-input"
                                      :prop="`jobs_distribution_data.${jobKey}`"
                                      :rules="{required: true, message: '比例不能为空', trigger: 'blur'}">
                            <el-input-number class="number-input1" v-model="addForm.jobs_distribution_data[`${jobKey}`]" :ref="`jobs_distribution_data_${jobKey}`"
                                             :min="0.01" :max="100.00" :precision="2" :controls="false"></el-input-number>
                        </el-form-item>
                        <span class="prompt-text">%</span>
                    </div>
                </div>
            </div>

            <div class="big-title">时段分布</div>
            <div class="form-item-1 flex">
                <div class="item-left-first" style="margin-bottom: 0"></div>
                <div class="item-right">
                    <div class="item-text">下单客户占比</div>
                    <div class="item-text">成交客户占比</div>
                </div>
            </div>
            <div class="form-item-1 flex" v-for="(timeItem, timeIndex) in addForm.times_distribution_data" :key="`times_${timeIndex}`">
                <div class="item-left-box">
                    <div class="item-left-first">{{timeIndex === 0 ? '0-6' : timeIndex === 1 ? '6~12' : timeIndex === 2 ? '12~18' : '18~24'}}</div>
                    <div class="item-left-first">波动范围</div>
                </div>
                <div class="item-right">
                    <div class="item-text">
                        <el-form-item :prop="`times_distribution_data.${timeIndex}.ordering_rate`"
                                      :rules="{required: true, message: '下单客户占比不能为空', trigger: 'blur'}">
                            <el-input-number class="number-input1" v-model="timeItem.ordering_rate" :min="0.00" :max="100.00" :precision="2" :controls="false" style="width: 182px"></el-input-number>
                            <span class="prompt-text">%</span>
                        </el-form-item>
                        <div class="flex">
                            <el-form-item :prop="`times_distribution_data.${timeIndex}.ordering_rate_range_min`"
                                          :rules="{required: true, message: '低值不能为空', trigger: 'blur'}">
                                <el-input-number class="number-input1" v-model="timeItem.ordering_rate_range_min"
                                                 :min="-99" :max="timeItem.ordering_rate_range_max ? timeItem.ordering_rate_range_max - 1 : 99" :precision="0" :controls="false"></el-input-number>
                            </el-form-item>
                            <span class="prompt-text">%</span>
                            <span class="prompt-text" style="margin-right: 4px">-</span>
                            <el-form-item :prop="`times_distribution_data.${timeIndex}.ordering_rate_range_max`"
                                          :rules="{required: true, message: '高值不能为空', trigger: 'blur'}">
                                <el-input-number class="number-input1" v-model="timeItem.ordering_rate_range_max"
                                                 :min="timeItem.ordering_rate_range_min ? timeItem.ordering_rate_range_min + 1 : -98" :max="100" :precision="0" :controls="false"></el-input-number>
                            </el-form-item>
                            <span class="prompt-text">%</span>
                        </div>
                    </div>
                    <div class="item-text">
                        <el-form-item :prop="`times_distribution_data.${timeIndex}.ordered_rate`"
                                      :rules="{required: true, message: '成交客户占比不能为空', trigger: 'blur'}">
                            <el-input-number class="number-input1" v-model="timeItem.ordered_rate" :min="0.00" :max="100.00" :precision="2" :controls="false" style="width: 182px"></el-input-number>
                            <span class="prompt-text">%</span>
                        </el-form-item>
                        <div class="flex">
                            <el-form-item :prop="`times_distribution_data.${timeIndex}.ordered_rate_range_min`"
                                          :rules="{required: true, message: '低值不能为空', trigger: 'blur'}">
                                <el-input-number class="number-input1" v-model="timeItem.ordered_rate_range_min"
                                                 :min="-99" :max="timeItem.ordered_rate_range_max ? timeItem.ordered_rate_range_max - 1 : 99" :precision="0" :controls="false"></el-input-number>
                            </el-form-item>
                            <span class="prompt-text">%</span>
                            <span class="prompt-text" style="margin-right: 4px">-</span>
                            <el-form-item :prop="`times_distribution_data.${timeIndex}.ordered_rate_range_max`"
                                          :rules="{required: true, message: '高值不能为空', trigger: 'blur'}">
                                <el-input-number class="number-input1" v-model="timeItem.ordered_rate_range_max"
                                                 :min="timeItem.ordered_rate_range_min ? timeItem.ordered_rate_range_min + 1 : -98" :max="100" :precision="0" :controls="false"></el-input-number>
                            </el-form-item>
                            <span class="prompt-text">%</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="big-title">属性偏好</div>
            <div class="form-item-2">
                <div class="item-box" v-for="(attrItem, attrKey, attrIndex) in addForm.attr_preference_data" :key="`attr_${attrIndex}`">
                    <div class="item-text" style="text-align: left">{{attrKey | filterAttr}}</div>
                    <div class="flex">
                        <el-form-item class="item-input"
                                      style="margin-right: 40px"
                                      v-for="(item, index) in attrItem"
                                      :prop="`attr_preference_data.${attrKey}.${index}`">
                            <el-input v-model.trim="attrItem[index]" :ref="`attr_preference_data_${attrKey}_${index}`"
                                      placeholder="请输入" maxlength="8" show-word-limit></el-input>
                        </el-form-item>
                    </div>
                </div>
            </div>

            <template v-for="(formItem, formKey, formIndex) in addForm">
                <template v-if="formKey === 'buy_power_distribution_data' || formKey === 'promotion_sensitivity_data' || formKey === 'evaluation_sensitivity_data' ||
                                formKey === 'buy_frequency_distribution_data' || formKey === 'finish_money_distribution_data'">
                    <div class="big-title">
                        <div>
                            {{formKey | filterAddForm}}
                            <span class="big-title-prompt">(总和是100%)</span>
                            <el-input size="medium" placeholder="总和" style="width: 100px;" disabled :value="totalScore[`${formKey}`]"></el-input>
                        </div>
                    </div>
                    <div class="form-item-2" :key="`addForm_${formIndex}`">
                        <div class="item-box" v-for="(fieldItem, fieldKey, fieldIndex) in formItem" :key="`formItem_${fieldIndex}`">
                            <div class="item-text">{{fieldKey | filterField}}</div>
                            <div class="flex">
                                <el-form-item class="item-input"
                                              :prop="`${formKey}.${fieldKey}`"
                                              :rules="{required: true, message: '比例不能为空', trigger: 'blur'}">
                                    <el-input-number class="number-input1" v-model="addForm[`${formKey}`][`${fieldKey}`]" :ref="`${formKey}_${fieldKey}`"
                                                     :min="0.01" :max="100.00" :precision="2" :controls="false"></el-input-number>
                                </el-form-item>
                                <span class="prompt-text">%</span>
                            </div>
                        </div>
                    </div>
                </template>
            </template>
        </el-form>

        <div style="text-align: center; margin: 40px 0">
            <el-button @click="goBack">上一步</el-button>
            <el-button type="primary" @click="submitForm('addForm')">下一步</el-button>
        </div>
    </div>
</template>

<script>
    import { dataCentorTplGetRegion, dataCentorTplAddCustomerAnalysis, dataCentorTplGetCustomerAnalysis } from '@/utils/apis.js'
    export default {
        filters: {
            filterProvinces(val) {
                let obj = {
                    1001: '华北地区',
                    1002: '华中地区',
                    1003: '华东地区',
                    1004: '华南地区',
                    1005: '东北地区',
                    1006: '西北地区',
                    1007: '西南地区',
                }
                return obj[val]
            },
            filterJob(val) {
                let obj = {
                    individual: '个体或服务业从业者',
                    company: '公司职工',
                    student: '学生',
                    farmer: '农民',
                    city: '城市其他职业',
                    worker: '工人',
                    government: '公务员',
                    teacher: '教职工',
                    village: '农村其他职业',
                    doctor: '医务人员',
                    finance: '金融从业者',
                }
                return obj[val]
            },
            filterAttr(val) {
                let obj = {
                    market_time: '上市时间',
                    feature: '功能',
                    colour: '颜色',
                    hotspot: '选购热点',
                    scene: '适用场景',
                }
                return obj[val]
            },
            filterAddForm(val) {
                let obj = {
                    buy_power_distribution_data: '购买力分布',
                    promotion_sensitivity_data: '促销敏感度',
                    evaluation_sensitivity_data: '评论敏感度',
                    buy_frequency_distribution_data: '近90天购买频次分布',
                    finish_money_distribution_data: '近90天成交金额分布',
                }
                return obj[val]
            },
            filterField(val) {
                let obj = {
                    loser: '收入很少',
                    blue: '蓝领',
                    low_white: '小白领',
                    advance_white: '高级白领',
                    luxury: '土豪',

                    promotion_no_sensitivity: '不敏感',
                    promotion_mild_sensitivity: '轻度敏感',
                    promotion_medium_sensitivity: '中度敏感',
                    promotion_high_sensitivity: '高度敏感',
                    promotion_exceeding_sensitivity: '极度敏感',

                    evaluation_no_sensitivity: '不敏感',
                    evaluation_mild_sensitivity: '轻度敏感',
                    evaluation_medium_sensitivity: '中度敏感',
                    evaluation_high_sensitivity: '高度敏感',
                    evaluation_exceeding_sensitivity: '极度敏感',

                    one: '一次',
                    two: '二次',
                    three: '三次',
                    four: '四次',
                    five: '五次',
                    other: '五次以上',

                    first: '0~159',
                    second: '159~199',
                    third: '199~369',
                    fourth: '369~699',
                    fifth: '699~740',
                    sixth: '740以上',
                }
                return obj[val]
            }
        },
        data() {
            return {
                addForm: {
                    provinces_distribution_data: [], // 省份分布
                    jobs_distribution_data: { // 职业分布比例
                        individual: void 0,
                        company: void 0,
                        student: void 0,
                        farmer: void 0,
                        city: void 0,
                        worker: void 0,
                        government: void 0,
                        teacher: void 0,
                        village: void 0,
                        doctor: void 0,
                        finance: void 0,
                    },
                    times_distribution_data: [ // 时段分布
                        {
                            ordering_rate: void 0, // 下单客户占比
                            ordering_rate_range_min: void 0,
                            ordering_rate_range_max: void 0,
                            ordered_rate: void 0, // 成交客户占比
                            ordered_rate_range_min: void 0,
                            ordered_rate_range_max: void 0,
                        },
                        {
                            ordering_rate: void 0, // 下单客户占比
                            ordering_rate_range_min: void 0,
                            ordering_rate_range_max: void 0,
                            ordered_rate: void 0, // 成交客户占比
                            ordered_rate_range_min: void 0,
                            ordered_rate_range_max: void 0,
                        },
                        {
                            ordering_rate: void 0, // 下单客户占比
                            ordering_rate_range_min: void 0,
                            ordering_rate_range_max: void 0,
                            ordered_rate: void 0, // 成交客户占比
                            ordered_rate_range_min: void 0,
                            ordered_rate_range_max: void 0,
                        },
                        {
                            ordering_rate: void 0, // 下单客户占比
                            ordering_rate_range_min: void 0,
                            ordering_rate_range_max: void 0,
                            ordered_rate: void 0, // 成交客户占比
                            ordered_rate_range_min: void 0,
                            ordered_rate_range_max: void 0,
                        },
                    ],
                    attr_preference_data: { // 属性偏好
                        market_time: [ '', '',  '',  '',  ''], // 上市时间
                        feature: [ '', '',  '',  '',  ''], // 功能
                        colour: [ '', '',  '',  '',  ''], // 颜色
                        hotspot: [ '', '',  '',  '',  ''], // 选购热点
                        scene: [ '', '',  '',  '',  ''], // 适用场景
                    },
                    buy_power_distribution_data: { // 购买力分布数据
                        loser: void 0,
                        blue: void 0,
                        low_white: void 0,
                        advance_white: void 0,
                        luxury: void 0,
                    },
                    promotion_sensitivity_data: { // 促销敏感度数据
                        promotion_no_sensitivity: void 0,
                        promotion_mild_sensitivity: void 0,
                        promotion_medium_sensitivity: void 0,
                        promotion_high_sensitivity: void 0,
                        promotion_exceeding_sensitivity: void 0,
                    },
                    evaluation_sensitivity_data: { // 评论敏感度数据
                        evaluation_no_sensitivity: void 0,
                        evaluation_mild_sensitivity: void 0,
                        evaluation_medium_sensitivity: void 0,
                        evaluation_high_sensitivity: void 0,
                        evaluation_exceeding_sensitivity: void 0,
                    },
                    buy_frequency_distribution_data: { // 近90天购买频次分布数据
                        one: void 0,
                        two: void 0,
                        three: void 0,
                        four: void 0,
                        five: void 0,
                        other: void 0,
                    },
                    finish_money_distribution_data: { // 近90天成交金额分布数据
                        first: void 0,
                        second: void 0,
                        third: void 0,
                        fourth: void 0,
                        fifth: void 0,
                        sixth: void 0,
                    },
                },
            }
        },
        computed: {
            // 比例总和
            totalScore() {
                return {
                    jobs_distribution_data: this.getTotalScore(this.addForm.jobs_distribution_data),
                    buy_power_distribution_data: this.getTotalScore(this.addForm.buy_power_distribution_data),
                    promotion_sensitivity_data: this.getTotalScore(this.addForm.promotion_sensitivity_data),
                    evaluation_sensitivity_data: this.getTotalScore(this.addForm.evaluation_sensitivity_data),
                    buy_frequency_distribution_data: this.getTotalScore(this.addForm.buy_frequency_distribution_data),
                    finish_money_distribution_data: this.getTotalScore(this.addForm.finish_money_distribution_data),
                }
            }
        },
        mounted() {
            this.getProvincesList()
        },
        methods: {
            // 获取详情
            getInfo() {
                let params = {
                    data_center_tpl_id: this.$route.query.data_center_tpl_id
                }
                dataCentorTplGetCustomerAnalysis(params).then((res) => {
                    this.addForm = res.data
                }).catch((err) => {})
            },
            // 计算比例总和
            getTotalScore(obj) {
                let total = 0
                for (const key in obj) {
                    if (isNaN(Number(obj[key]))) {
                        total += 0
                    } else {
                        total += Number(obj[key])
                    }
                }
                return total
            },
            // 获取省份分布
            getProvincesList() {
                dataCentorTplGetRegion().then((res) => {
                    this.addForm.provinces_distribution_data = res.data.map(item => {
                        return {
                            region_code: item.region_code,
                            finish_customer_number: void 0,
                            finish_customer_number_range_min: void 0,
                            finish_customer_number_range_max: void 0,
                            finish_price: void 0,
                            finish_price_range_min: void 0,
                            finish_price_range_max: void 0,
                        }
                    })
                    this.getInfo()
                }).catch((err) => {})
            },
            // 上一步
            goBack() {
                this.$router.push({
                    path: '/practice/dataStepThree',
                    query: {
                        stepActive: 3,
                        goodClassId: Number(this.$route.query.goodClassId),
                        data_center_tpl_id: Number(this.$route.query.data_center_tpl_id),
                    }
                })
            },
            // 判断总分不为100的情况，终止下一步并提示
            getTotal(objTmp, field) {
                let totalScore = 0
                for (const key in objTmp) {
                    let item = objTmp[key]
                    totalScore += item
                }
                for (const key in objTmp) {
                    if (!totalScore || totalScore !== 100) {
                        this.$refs[`${field}_${key}`][0].$el.classList.add('isError')
                    } else {
                        this.$refs[`${field}_${key}`][0].$el.classList.remove('isError')
                    }
                }
                return totalScore && totalScore === 100
            },
            // 下一步
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        // 职业分布比例不为100的话给提示
                        if (!this.getTotal(this.addForm.jobs_distribution_data, 'jobs_distribution_data')) {
                            return this.$message.warning('职业分布比例的总分应为100%')
                        }

                        // 属性偏好至少一个，没有填的话提示至少一个
                        for (const key in this.addForm.attr_preference_data) {
                            let item = this.addForm.attr_preference_data[key]
                            let lengthTmp = 0
                            for (let i = 0; i < item.length; i++) {
                                if (!item[i]) {
                                    lengthTmp++
                                }
                            }
                            for (let i = 0; i < item.length; i++) {
                                if (lengthTmp === item.length) {
                                    this.$refs[`attr_preference_data_${key}_${i}`][0].$el.classList.add('isError')
                                    return this.$message.warning(`属性偏好的${key === 'market_time' ? '上市时间' :
                                                                                    key === 'feature' ? '功能' :
                                                                                        key === 'colour' ? '颜色' :
                                                                                            key === 'hotspot' ? '选购热点' : '适用场景'}至少填一个`)
                                } else {
                                    this.$refs[`attr_preference_data_${key}_${i}`][0].$el.classList.remove('isError')
                                }
                            }
                        }

                        // 购买力分布比例不为100的话给提示
                        if (!this.getTotal(this.addForm.buy_power_distribution_data, 'buy_power_distribution_data')) {
                            return this.$message.warning('购买力分布比例的总分应为100%')
                        }
                        // 促销敏感度比例不为100的话给提示
                        if (!this.getTotal(this.addForm.promotion_sensitivity_data, 'promotion_sensitivity_data')) {
                            return this.$message.warning('促销敏感度比例的总分应为100%')
                        }
                        // 评论敏感度比例不为100的话给提示
                        if (!this.getTotal(this.addForm.evaluation_sensitivity_data, 'evaluation_sensitivity_data')) {
                            return this.$message.warning('评论敏感度比例的总分应为100%')
                        }
                        // 近90天购买频次分布比例不为100的话给提示
                        if (!this.getTotal(this.addForm.buy_frequency_distribution_data, 'buy_frequency_distribution_data')) {
                            return this.$message.warning('近90天购买频次分布比例的总分应为100%')
                        }
                        // 近90天成交金额分布分布比例不为100的话给提示
                        if (!this.getTotal(this.addForm.finish_money_distribution_data, 'finish_money_distribution_data')) {
                            return this.$message.warning('近90天成交金额分布比例的总分应为100%')
                        }

                        let formData = new FormData()
                        formData.append('data_center_tpl_id', this.$route.query.data_center_tpl_id)
                        formData.append('provinces_distribution_data', JSON.stringify(this.addForm.provinces_distribution_data))
                        formData.append('jobs_distribution_data', JSON.stringify(this.addForm.jobs_distribution_data))
                        formData.append('times_distribution_data', JSON.stringify(this.addForm.times_distribution_data))
                        formData.append('attr_preference_data', JSON.stringify(this.addForm.attr_preference_data))
                        formData.append('buy_power_distribution_data', JSON.stringify(this.addForm.buy_power_distribution_data))
                        formData.append('promotion_sensitivity_data', JSON.stringify(this.addForm.promotion_sensitivity_data))
                        formData.append('evaluation_sensitivity_data', JSON.stringify(this.addForm.evaluation_sensitivity_data))
                        formData.append('buy_frequency_distribution_data', JSON.stringify(this.addForm.buy_frequency_distribution_data))
                        formData.append('finish_money_distribution_data', JSON.stringify(this.addForm.finish_money_distribution_data))
                        dataCentorTplAddCustomerAnalysis(formData).then((res) => {
                            this.$message.success(res.msg)
                            this.$router.push({
                                path: '/practice/dataStepFive',
                                query: {
                                    stepActive: 5,
                                    goodClassId: Number(this.$route.query.goodClassId),
                                    data_center_tpl_id: Number(this.$route.query.data_center_tpl_id),
                                }
                            })
                        }).catch((err) => {})
                    } else {
                        return false;
                    }
                });
            }
        }
    }
</script>

<style scoped lang="scss">
    .demo-ruleForm {
        ::v-deep .el-form-item__label {
            font-size: 16px;
            color: #333;
        }
    }
    .big-title {
        margin: 40px 0;
        font-size: 16px;
        padding-left: 14px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        &:before {
            content: '';
            width: 4px;
            height: 16px;
            background: #2DC079;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            border-radius: 2px;
        }
    }
    .form-item-1 {
        font-size: 16px;
        .item-left-box {
            display: flex;
            flex-direction: column;
        }
        .item-left-first {
            width: 110px;
            height: 40px;
            line-height: 40px;
            text-align: right;
            margin-right: 16px;
            margin-bottom: 22px;
        }
        .item-right {
            width: 1%;
            flex: 1;
            display: flex;
        }
        .item-text {
            width: 201px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            margin-right: 40px;
        }
    }
    .form-item-2 {
        display: flex;
        flex-wrap: wrap;
        .item-box {
            margin-right: 40px;
        }
        .item-text {
            width: 182px;
            line-height: 1;
            margin-bottom: 10px;
            text-align: center;
            font-size: 16px;
        }
        .item-input {
            width: 182px;
        }
    }
    .prompt-text {
        font-size: 16px;
        margin-left: 4px;
        color: #999;
        height: 40px;
        line-height: 40px;
    }
    .number-input1 {
        width: 100%;
    }
    ::v-deep .number-input1 {
        &.isError {
            .el-input__inner {
                border-color: #FD4446;
            }
        }
    }
    .big-title-prompt {
        color: #999;
        font-size: 14px;
        margin-left: 6px;
        margin-right: 10px;
    }
</style>